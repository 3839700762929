import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import SEO from '../../components/seo';
import Image from '../../components/image';
import ImageLandscape from '../../components/sub/landscape';
export const frontmatter = {
  title: "Streaming Style: How Netflix Can Save Retail",
  date: "2018-10-31",
  author: "Roberto Ramos",
  teaserImage: "../../../static/media/20181031-netflix.jpg",
  path: "2018-10-31-How-Netflix-Can-Save-Retail",
  teaserText: "inge watching is a real thing, serving as the perfect antidote to our stressed out, trigger-ready anxiety, fueled by today’s global surface noise."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO image="http://dev.doneger.com/media/20181031-netflix.jpg" title="Streaming Style: How Netflix Can Save Retail" description="inge watching is a real thing, serving as the perfect antidote to our stressed out, trigger-ready anxiety, fueled by today’s global surface noise. " author='Roberto Ramos' keywords={['Netflix', 'Athleta']} />
      <ImageLandscape imageSrc="20181031-netflix.jpg" />
      <MDXTag name="p" components={components}>{`Netflix has made the personal journey into storytelling more addictive than ever. Binge watching is a real thing, serving as the perfect antidote to our stressed out, trigger-ready anxiety, fueled by today’s global surface noise. Netflix has become a proxy for the collective longing for escapism, and by doing so, it will shape how current and future generations consume and create culture. Lifestyle and fashion brands are already feeling this effect as they try to find ways to engage a consumer that is time poor and experience hungry. And by experience, they are looking for a validation of the way they see the world as well as the promise of discovery and adventure. In a sea of sameness, consumers want brands that stand for something. The new form of innovation sits at the intersection of purpose and strategy. The powerful brands find unique ways of telling their story and establishing a relationship of engagement around it.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Content Is Everything`}</MDXTag>
      <MDXTag name="p" components={components}>{`We are living in a golden age of content culture and easy access to digital, interactive storytelling. And we can thank venture capitalism, creative entrepreneurship and Netflix’s sexy-smart algorithms for this phenomenon. Of course, we also have a talented creative class that has come beautifully unhinged with the collapse of the old entertainment complex. The fashion and retail industries, especially those big-box retailers caught up in retail’s massive evolution, should take note.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The biggest impact of the Netflix culture-download model is the consumer. This golden age of content is making us more culturally savvy and open to deeper, more engaging forms of storytelling and dialogue. This is cool. It democratizes content without national borders. Longform storytelling is also meaningful in response to the swift, spontaneous and mostly ephemeral nature of social media and its preference for formats such as memes. The Netflix effect reflects our need for finding something unknown, providing deep layers of discovery in its offerings.`}</MDXTag>
      <MDXTag name="p" components={components}>{`And this is the Netflix lesson for retailers: Create layers of discovery experiences for your guests, favoring more immersive lifestyle environments versus traditional, item-driven merchandising strategies.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Next-Gen Discovery`}</MDXTag>
      <MDXTag name="p" components={components}>{`Mainstreaming culture-on-demand, à la Netflix, Hulu and others, will require fueling the next base of creative talent, including our future rock star designers, editors and hyphenated-lifestyle entrepreneurs. These young kids who are now binging on indie comedies and cult-level Scandinavian thrillers are having their aesthetic and storytelling chops greatly amplified by Netflix’s rich archives. Modern, character-driven entertainment is showing us is the importance and influence of unique personality. Retailers should take copious notes. Our retail industry must offer this generation a home to express themselves as well as reinvent our offerings and experiences. Success will come by providing co-curated, co-storytelling experiences.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Six Netflix Lessons For Retail`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here are some lessons Netflix can teach retailers how to become culture disruptors that break through.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`1.	Think Like an Entertainment Company.`}</MDXTag>{` Dial up your understanding of the current cultural conversation and identify stories and experiences where your brand can make an authentic cultural statement. Go beyond your comfort zone to identify new collaborators, writers, producers and directors whose sensitivities and stories marry well with your vision and vibe.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`2.	Blend Purpose with Creativity.`}</MDXTag>{` Take chances in terms of your creative statements. Deliver storytelling based on the powerful edges of your brand. Think of elements that will spark conversation and will set you aside from the rest. In today’s marketplace, an awful day is to be stuck in the middle; find your voice and amplify the uniqueness of your story. Tap into your purpose and vision for creative confidence. Patagonia did by taking on the President of the United States and his administration’s position on environmental policies. Apply your own vision of creativity to the entire consumer journey, enriching the discovery experience with interesting background facts about the craftsmanship of the products you offer. Make the selling floor more intuitive, evocative and welcoming. Tap into the emotions of your customers.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`3.	Focus on Conversations.`}</MDXTag>{` The return to scripted drama reflects the importance of dialogue and deep relationships. On this front, Netflix is innovating by leveraging technology and consumer engagement to make some stories fully interactive by choosing different narratives and endings. Invite your customers to become part of the adventure of your brand. Explore how to nurture a conversation with customers on all fronts, and engage with influencers and collaborators to enhance your product, experience and how you build relationships with your most important customers.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`4.	Give Marketing Campaigns More Depth.`}</MDXTag>{` Consumers’ appreciation for creative depth and cadence opens the door for more impactful marketing campaigns. Explore creative ways of taking your story and messaging to different media. Find ways of dialing up interactivity, including ways to make the consumer more involved. Think, for instance, how Nike encourages its sneakerhead followers to create campaigns and promotions through specific challenges and tasks that express their belonging to a specific tribe. Finally, consider the great, creative grit content you have based on behind-the-scenes stories. What are the backstories about brands, designers and craftsmanship? Tap into the popularity of documentaries to develop brand ethos messaging that feeds a creatively hungry generation.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`5.	Deliver Personalization.`}</MDXTag>{` Netflix has perfected the art of mass curation. Think of ways in which you can deliver personalization at scale. Explore more targeted content, niche product offerings, and whenever possible, a more high-touch, emotive customer service and sales approach. Bring this to life in store by customizing environments; deploy the art of the high touch through personalized services including personal shoppers, monogramming, and more. And turn this human interaction into a research source for more insights and data to further enrich the human experience you customers long for.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`6.	Foster a People Culture.`}</MDXTag>{` Netflix broke new ground with its “Freedom & Responsibility” HR manifesto giving more power to its employees. Find ways of encouraging your organization to take more creative risks, to be more entrepreneurial and to be better, stronger and authentically cool brand ambassadors. For all its reputation around creativity, the fashion industry can do a better job at creating a people-centric approach that allows creativity to thrive. That will be the foundation for creating a true, magical retail experience.`}</MDXTag>
      <MDXTag name="p" components={components}>{`There is something primordially evocative about stories. It starts when we are children when we lived in the world of make-believe and fantasy realities. Stories unlocked our imagination, curiosity and a world of possibilities. As adults, we’ve lost that magic over the years and crave it. The desire for experiences is an open invitation for fashion and retail to step in and create magical experiences and connection. So, go ahead and shed your need for creative security aside. Start taking chances and exploring ways to make your brand a story to remember.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    